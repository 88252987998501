import { Component } from '@angular/core';

@Component({
    selector: 'app-mailchimp-signup-modal',
    imports: [],
    templateUrl: './mailchimp-signup-modal.component.html',
    styleUrl: './mailchimp-signup-modal.component.scss'
})
export class MailchimpSignupModalComponent {

}
