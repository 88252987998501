import {
  Component,
  ElementRef,
  TemplateRef,
  inject,
  signal,
  viewChild,
} from '@angular/core';
import { WinnersCarouselComponent } from './winners-carousel/winners-carousel.component';
import { GalleryComponent } from '../gallery/gallery.component';
import {
  BsModalRef,
  BsModalService,
  ModalModule,
  ModalOptions,
} from 'ngx-bootstrap/modal';
import { MailchimpSignupModalComponent } from '../shared/mailchimp-signup-modal/mailchimp-signup-modal.component';
import { DiamondsComponent } from './diamonds/diamonds.component';
import { transition, style, animate, trigger } from '@angular/animations';
import { fadeIn } from '../../shared/animations/animations';

export enum HoldingPageTab {
  Winners,
  Gallery,
}
export enum SocialType {
  X,
  Instagram,
  LinkedIn,
}

@Component({
    selector: 'app-holding-page',
    imports: [
        WinnersCarouselComponent,
        GalleryComponent,
        ModalModule,
        DiamondsComponent,
    ],
    animations: [fadeIn],
    templateUrl: './holding-page.component.html',
    styleUrl: './holding-page.component.scss'
})
export class HoldingPageComponent {
  HoldingPageTab = HoldingPageTab;
  SocialType = SocialType;
  selectedTab = signal(HoldingPageTab.Winners);
  modalService = inject(BsModalService);

  selectTab(tab: HoldingPageTab) {
    this.selectedTab.set(tab);
  }

  openMailingListModal() {
    this.modalService.show(MailchimpSignupModalComponent, {});
  }
  goToSocial(type: SocialType) {
    switch (type) {
      case SocialType.Instagram: {
        window.open('https://www.instagram.com/developherawards', '_blank');
        break;
      }
      case SocialType.LinkedIn: {
        window.open(
          'https://www.linkedin.com/company/developher-awards',
          '_blank'
        );
        break;
      }
      case SocialType.X: {
        window.open('https://www.x.com/syncdevelopher');
        break;
      }
    }
  }

  openOldSite() {
    window.open('https://old.developherawards.com');
  }
}
