import { Component, HostListener, OnInit, ViewChild, inject } from '@angular/core';

import { BsDropdownDirective, BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CommonModule, DOCUMENT } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    imports: [CommonModule, BsDropdownModule, RouterModule],
    providers: [BsDropdownDirective],
    animations: [
        trigger('navView', [
            state('show', style({
                opacity: 1,
            })),
            state('hide', style({
                opacity: 0,
            })),
            transition('show => hide', animate('700ms ease-out')),
            transition('hide => show', animate('700ms ease-in')),
        ]),
    ]
})
export class HeaderComponent {
  private document = inject<Document>(DOCUMENT);

  router = inject(Router);
  open: boolean = false;
  currentPosition = this.document?.defaultView?.scrollY ?? 0;

  state = 'show';
  @HostListener('window:scroll', ['$event'])
  onScroll(e: any) {
    let scroll = e.target.scrollingElement.scrollTop + 100;
    if (scroll === 100) {
      let nav = document.querySelector('#main-nav');
      this.state = 'show';
      return;
    }
    if (scroll > this.currentPosition) {
      let nav = document.querySelector('#main-nav');
      this.state = 'hide';
    } else {
      let nav = document.querySelector('#main-nav');
      this.state = 'show';
    }
    this.currentPosition = scroll;
  }

  toggle() {
    this.open = !this.open;
  }
  sponsor() {
    this.toggle();
    if (this.document?.defaultView) {
      this.document.defaultView.location.href =
        'mailto:vickie@vickieallen.co.uk?subject=Sponsorship enquiry';
    }
  }
}
